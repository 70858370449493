import Component from 'vue-class-component'
import BaseSignUpMixin from '../../mixins/baseSignUpMixin'

@Component({
  components: {
    AgreeToMarketingCheckbox: () => import('../../../../components/AgreeToMarketingCheckbox.vue')
  }
})
export default class EmailSignUp extends BaseSignUpMixin {

}
