import { Component } from 'vue-property-decorator'
import BaseSignUpMixin from '../../mixins/baseSignUpMixin'
import SocialLoginButtons from '../../components/LoginButtons.vue'

@Component({
  components: {
    SocialLoginButtons
  }
})
export default class MethodSelection extends BaseSignUpMixin {
  onSuccessfulLogin () {
    this.goToStage('')
  }
}
