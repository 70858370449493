import { Component, Prop, Watch } from 'vue-property-decorator'
import BaseSignUpMixin from '../mixins/baseSignUpMixin'
import { AuthTermsConsentData } from '../../../store/modules/auth/types'
import { LoginResultDto } from 'booksprout'
import { AUTH_SUCCESS } from '../../../store/actions/auth'
import { AuthServiceError } from '../../../modules/_base/auth/auth.service.interface'
import { Getter } from 'vuex-class'

@Component
export default class LoginButtons extends BaseSignUpMixin {
  @Prop({ type: Boolean}) readonly hideEmailButton!: boolean

  @Getter('termsConsentNeeded') termsConsentNeeded!: boolean
  @Getter('termsConsentData') termsConsentData!: AuthTermsConsentData

  @Watch('termsConsentNeeded')
  onTermsConsentNeededChange (value: boolean) {
    if (value) {
      void this.showOAuthTerms(
        this.termsConsentData.loginResult || new LoginResultDto(),
        'login',
        this.onConsentGiven
      )
    }
  }

  onConsentGiven () {
    return this.$store.dispatch(AUTH_SUCCESS, {
      loginResult: {
        ...this.termsConsentData.loginResult,
        oAuthRegistered: false,
        termsConsentFailed: false,
        termsConsentNeeded: false
      }
    }).then((loginResult) => {
      return this.userService.consentGiven().then(() => {
        // the dialog will be shown as part of onTermsConsentNeededChange
        // don't show again
        this.onSuccessfulLogin(loginResult, false)
      }).catch((e: AuthServiceError) => {
        this.showError(e.message)
      })
    }).catch((e: AuthServiceError) => {
      this.showError(e.message)
    })
  }
}
