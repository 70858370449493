import { Component } from 'vue-property-decorator'
import BaseSignUpMixin from '../../mixins/baseSignUpMixin'
import { AUTH_SUCCESS, SET_USER } from 'booksprout-app'
import { LoginResultDto, UserDto } from 'booksprout'

@Component({
  data () {
    return {
      selectedRoles: []
    }
  }
})
export default class SelectRole extends BaseSignUpMixin {
  selectedRoles: number[] = []
  vaRole = 999

  get vaBtnSelected () {
    return this.selectedRoles.includes(this.vaRole)
  }

  setRole (role: number) {
    // Clear array if button clicked and it's NOT a child of VA
    if (![this.MODULE_CONSTANTS.USER.ROLE_SELECTION.VA.READER, this.MODULE_CONSTANTS.USER.ROLE_SELECTION.VA.AUTHOR].includes(role)) {
      this.selectedRoles = []
    }

    // if we've already selected a child of VA then reset to just VA before pushing the new role
    if (
      this.selectedRoles.includes(this.MODULE_CONSTANTS.USER.ROLE_SELECTION.VA.READER) ||
      this.selectedRoles.includes(this.MODULE_CONSTANTS.USER.ROLE_SELECTION.VA.AUTHOR)
    ) {
      this.selectedRoles = [this.vaRole]
    }

    // Push the role so we know which button to add highlight classes etc to.
    this.selectedRoles.push(role)

    // If we have a "final" selection role (i.e not VA main button)
    if (this.selectedRoles.length > 1 || this.selectedRoles[0] !== this.vaRole) {
      // Update the user with their selected role then forward them to where they should be
      this.authService.updateUserSelectedRole(role).then((loginResult: LoginResultDto) => {
        this.$store.dispatch(AUTH_SUCCESS, {
          loginResult
        }).then(() => {
          if (
            this.selectedRoles.includes(this.MODULE_CONSTANTS.USER.ROLE_SELECTION.READER) ||
            this.selectedRoles.includes(this.MODULE_CONSTANTS.USER.ROLE_SELECTION.VA.READER)
          ) {
            const forwardQuery = this.$route.query.forward
            this.navigateToOtherApp('reviewer' + (forwardQuery ? forwardQuery : ''))
          } else if (
            this.selectedRoles.includes(this.MODULE_CONSTANTS.USER.ROLE_SELECTION.AUTHOR) ||
            this.selectedRoles.includes(this.MODULE_CONSTANTS.USER.ROLE_SELECTION.VA.AUTHOR)
          ) {
            if (this.mimicOrAuthenticatedUser.permissionQuery.isDemoFeature) {
              this.userService.update({
                id: this.mimicOrAuthenticatedUser.id,
                authorRole: this.MODULE_CONSTANTS.USER.ROLES.AUTHOR.BEST_SELLING
              }).then((user: UserDto) => {
                return this.$store.dispatch(SET_USER, user).then(() => {
                  this.$router.push('/arcs')
                })
              })
            } else {
              this.navigateToOtherApp('publisher/settings/billing/login-select-plan?fl=1')
            }
          } else if (this.selectedRoles.includes(this.MODULE_CONSTANTS.USER.ROLE_SELECTION.PUBLISHER)) {
            if (this.mimicOrAuthenticatedUser.permissionQuery.isDemoFeature) {
              this.userService.update({
                id: this.mimicOrAuthenticatedUser.id,
                authorRole: this.MODULE_CONSTANTS.USER.ROLES.AUTHOR.BEST_SELLING
              }).then((user: UserDto) => {
                return this.$store.dispatch(SET_USER, user).then(() => {
                  this.$router.push('/arcs')
                })
              })
            } else {
              this.navigateToOtherApp('publisher/settings/billing/login-select-plan?fl=1&p=1')
            }
          }
        }).catch(e => {
          this.showError(e.message)
        })
      }).catch((e: any) => {
        this.showError(e.message)
      })
    }
  }
}
