import { Component } from 'vue-property-decorator'

import MethodSelection from './MethodSelection.vue'
import BaseSignUpMixin from '../../mixins/baseSignUpMixin'
import EmailSignUp from './EmailSignUp.vue'
import SelectRole from './SelectRole.vue'
import VerifyEmail from './VerifyEmail.vue'

@Component({
  components: {
    MethodSelection,
    EmailSignUp,
    SelectRole,
    VerifyEmail
  }
})
export default class SignUpComponent extends BaseSignUpMixin {
  public get stageComponent () {
    switch (this.$route.params.stage) {
      case 'email': return 'email-sign-up'
      case 'select-role': return 'select-role'
      case 'payment': return 'payment'
      case 'billing': return 'billing'
      case 'verify-email': return 'verify-email'
      default: return 'method-selection'
    }
  }

  created () {
    this.setPageMeta('signUp')
  }
}
