import { Component } from 'vue-property-decorator'
import { SET_USER } from '../../../../store/actions/auth'
import { validStr } from 'booksprout'
import BaseSignUpMixin from '../../mixins/baseSignUpMixin'

@Component({
  data () {
    return {
      collectEmailAddress: void 0
    }
  }
})
export default class VerifyEmail extends BaseSignUpMixin {
  collectEmailAddress!: boolean
  emailAddressToVerify: undefined | string  = ''
  showResendOption = false
  isEditingEmail = false

  get emailValidationToken () {
    // I know this looks odd but the route is used in multiple places so the token is actually passed as the tab param
    return this.$route.params.tab
  }

  get completing () {
    return this.emailValidationToken !== void 0
  }

  resendVerificationEmail () {
    return this.authService.resendEmailVerificationEmail(this.mimicOrAuthenticatedUser.emailAddress + '').then(() => {
      this.showMessage(this.$t('modules.settings.labels.verificationEmailSent', [this.mimicOrAuthenticatedUser.emailAddress]))
    }).catch(() => {
      this.showError(this.$tc('system.errors.somethingWentWrong'))
    })
  }

  updateEmailAndResendVerificationEmail () {
    return this.authService.updateUserEmailAddress(
      this.mimicOrAuthenticatedUser.emailAddress + '',
      this.emailAddressToVerify + ''
    ).then(user => {
      this.$store.dispatch(SET_USER, user).then(() => {
        this.isEditingEmail = false
        this.showMessage(this.$tc('modules.signUp.labels.emailUpdated'))
      })
    }).catch(e => {
      if (e.message === this.$t('system.errors.emailExists')) {
        void this.resendVerificationEmail()
      } else {
        this.showError(e.message)
      }
    })
  }

  verifyEmail () {
    // They shouldn't be here - move along
    if (this.mimicOrAuthenticatedUser?.userEmails?.every(e => e.status === this.MODULE_CONSTANTS.USER.EMAILS.STATUS.VERIFIED)) {
      this.doRedirect()
    } else {
      this.authService.completeVerificationEmail(this.emailValidationToken).then(user => {
        return this.$store.dispatch(SET_USER, user).then(() => {
          this.showMessage(this.$tc('modules.signUp.labels.emailVerified'))
          this.doRedirect()
        })
      }).catch(e => {
        this.showError(e.message)
      })
    }
  }

  doRedirect () {
    if (this.mimicOrAuthenticatedUser.reviewerRole === this.MODULE_CONSTANTS.USER.ROLES.REVIEWER.UNSET) {
      void this.$router.push('/sign-up/select-role').catch(() => {
        // do nothing, routeGuard redirected user
        // fixes "Uncaught (in promise) undefined" error
      })
    } else if (validStr(this.mimicOrAuthenticatedUser.authorRole)) {
      this.navigateToOtherApp('publisher')
    } else {
      this.navigateToOtherApp('reviewer')
    }
  }

  mounted () {
    // if the user is here but not auth'd just send them back to root.
    if (!this.isAuthenticated && !this.collectEmailAddress) {
      void this.$router.push('/')
    }

    if (this.completing) {
      this.verifyEmail()
    } else {
      this.emailAddressToVerify = this.mimicOrAuthenticatedUser.emailAddress
      this.collectEmailAddress = !validStr(this.emailAddressToVerify)
      this.isEditingEmail = this.collectEmailAddress

      setTimeout(() => {
        this.showResendOption = true
      }, 30000)
    }
  }
}
